BarcodeForm.css

form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 2rem auto;
    padding: 1.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}

label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    font-size: 1rem;
}

input, select {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

button {
    padding: 0.75rem;
    font-size: 1rem;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #5ff0bb;
}

@media (max-width: 480px) {
    form {
        padding: 1rem;
        margin: 1rem;
    }
}
